import { useMutation, useQuery } from "@apollo/client"
import { CheckBox } from "@mui/icons-material"
import { CircularProgress, FormControlLabel, Stack } from "@mui/material"
import { ModalLayout } from "components/ModalLayout"
import { Select } from "components/Select"
import { UPDATE_USER_TASK } from "graphql/mutations/updateUserTask"
import { FETCH_USERS } from "graphql/queries/fetchUsers"
import { useSnackbar } from "notistack"
import { FormProvider, useForm } from "react-hook-form"
import { FetchUsers } from "types/graphql/queries/fetchUsers"
import { ModalLayoutProps } from "types/ModalLayout"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Task } from "types/graphql/queries/detailProtocol"

type ModalDesignateResponsibleProps = ModalLayoutProps & {
    refetch: () => void
    task: Task
}

const schema = yup.object().shape({
    responsible: yup.string().required("Campo obrigatório")
})

export const ModalSearchUsers = ({ open, handleClose, refetch, task }: ModalDesignateResponsibleProps) => {

    const { data, loading } = useQuery<FetchUsers>(FETCH_USERS, {
        variables: {
            limit: 1000,
            status: "Ativo",
            order_by: "users.name",
            direction: "asc",
        }
    })

    const [updateUserTask, { loading: loadingUpdate }] = useMutation(UPDATE_USER_TASK, {
        onCompleted: () => {
            refetch()
            handleClose()
        }
    })
    const { enqueueSnackbar } = useSnackbar()

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            responsible: "",
        }
    })

    const { handleSubmit, reset } = methods

    const onSubmit = async (data: any) => {

        try {
            await updateUserTask({
                variables: {
                    id: String(task?.id),
                    user_id: String(data.responsible),
                    ignore_profile_id: true
                }
            })

            enqueueSnackbar("Responsável atualizado com sucesso!", {
                variant: "success",
            })

            refetch()
            handleClose()
            reset({
                responsible: "",
            })
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: "error",
            })
        }
    }

    return (
        <FormProvider {...methods}>
            <ModalLayout open={open} handleClose={handleClose} title="Atualizar o responsável pela a tarefa" handleSubmit={handleSubmit(onSubmit)} isLoading={loadingUpdate}>
                <Stack spacing={2} marginTop={2}>
                    {loading && <CircularProgress />}
                    {data && (
                        <>
                            <Select label="Selecionar o responsável" options={data.fetchUsers.data.map((item) => ({
                                label: item.name,
                                value: item.id
                            }))}
                                name="responsible"
                            />
                        </>
                    )}
                </Stack>
            </ModalLayout>
        </FormProvider>
    )
}