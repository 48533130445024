import { gql } from "@apollo/client";
export const UPDATE_USER_TASK = gql`
    mutation UpdateUserTask (
        $id: ID!,
        $user_id: ID!,
        $ignore_profile_id: Boolean
    )
    {
        updateUserTask(
            id: $id,
            user_id: $user_id,
            ignore_profile_id: $ignore_profile_id
        )
    }
    `