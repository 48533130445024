import {
	Box,
	Typography,
	Switch,
	FormGroup,
	FormControlLabel,
	Alert,
	Button,
} from "@mui/material";
import { Item } from "components/Paper";
import { Title } from "components/Title";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CheckIcon from "@mui/icons-material/Check";

import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	timelineItemClasses,
	TimelineSeparator,
} from "@mui/lab";
import { DetailPontuation } from "./DetailPontuation";
import { DetailConditional } from "./DetailConditional";
import { DetailAssignment } from "./DetailAssignment";
import { DetailOccurrence } from "./DetailOccurrence";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import { DetailUndoTask } from "./DetailUndoTask";
import UndoIcon from "@mui/icons-material/Undo";
import { useEffect, useState } from "react";
import { ModalSearchUsers } from "./ModalSearchUsers";
import { useAuthContext } from "context/AuthProvider";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

interface TasksProps {
	tasks: Array<Task>;
	subject: DetailProtocol["detailProtocol"]["subject"];
	status: string;
	refetch: () => void;
	auto_finish_on_complaint_refused?: boolean;
	showUndoTasks?: boolean;
	setShowUndoTasks?: (value: boolean) => void;
}

export function Tasks({
	tasks,
	status,
	refetch,
	subject,
	auto_finish_on_complaint_refused,
	showUndoTasks,
	setShowUndoTasks,
}: TasksProps) {
	const [formatedTasks, setFormatedTasks] = useState<Task[]>([]);
	const [openModalUpdateUser, setOpenModalUpdateUser] = useState(false)
	const [task, setTask] = useState(null)
	const { state } = useAuthContext()
	const occurrenceFilter = tasks.find(
		(task) => task.type === "Ocorrência" && task?.detail?.form_response != null
	);

	const occurrenceResponseFilter = occurrenceFilter?.detail?.form_response;

	const handleModalSearchUsersUpdate = (data: any) => {
		setTask(data)
		setOpenModalUpdateUser(true)
	}

	const renderItemTimeline = (
		type: string,
		item: Task,
		isNotConcluded: boolean
	) => {

		switch (type) {
			case "Pontuação":
				return (
					<DetailPontuation
						title={item.name}
						points={item.points}
						concluded_at={item.concluded_at}
						concluded_by={item.user?.name}
						matricula={item.user?.params?.matricula}
						undo_at={item.undo_at}
					/>
				);
			case "Condicional":
				return (
					<DetailConditional
						task={item}
						isNotConcluded={isNotConcluded}
						refetch={refetch}
						status={status}
						auto_finish_on_complaint_refused={auto_finish_on_complaint_refused}
					/>
				);
			case "Tarefa":
				return (
					<DetailAssignment
						task={item}
						isNotConcluded={isNotConcluded}
						refetch={refetch}
						status={status}
					/>
				);
			case "Ocorrência":
				return (
					<DetailOccurrence
						task={item}
						isNotConcluded={isNotConcluded}
						refetch={refetch}
						status={status}
						subject={subject}
						formResponseOccurrence={occurrenceResponseFilter}
					/>
				);
			case "Desfeita":
				return <DetailUndoTask task={item} />;
			case "Unknow":
				return <Typography> Tipo de tarefa desconhecida</Typography>;
			default:
				return <Typography> Tipo de tarefa desconhecida</Typography>;
		}
	};
	const isSuspendedOrCanceled = status === "Cancelado" || status === "Suspenso";

	const issetUndoTask = tasks.some(
		(item) => item.type === "Desfeita" || item.undo_at !== null
	);

	useEffect(() => {
		if (showUndoTasks) {
			setFormatedTasks(tasks);
		} else {
			const items = tasks.filter(
				(item) => item.type !== "Desfeita" && !item.undo_at
			);
			setFormatedTasks(items);
		}
	}, [showUndoTasks, tasks]);

	const hasRestriction = state?.userInfo?.user?.restrictions?.some(
		(restriction: { id: number; name: string; code: string }) => restriction.code === "TAREFA_ATUALIZAR_USUARIO"
	) || false


	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
					marginY: 2,
				}}
			>
				<Box marginBottom={2}>
					{tasks.some(task => task.concluded_at === null && task.type !== "Desfeita" && task.advance_next_task === true) && (
						<>
							{(() => {
								const taskWithPerfil = tasks.find(task => task.concluded_at === null && task.perfil);

								return (
									<Alert severity="warning">
										Existe tarefa pendente {""}
										{taskWithPerfil ?
											<span dangerouslySetInnerHTML={{ __html: `do perfil <strong>${taskWithPerfil.perfil.name}</strong>` }} />
											: ''} {""}
										neste protocolo.
									</Alert>
								);
							})()}
						</>
					)}
				</Box>
				<Item
					sx={{
						height: "100%",
						color: "rgba(0, 0, 0, 0.87)",
						lineHeight: "150%",
						position: "relative",
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Title title="Tarefas" />
						{issetUndoTask && (
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={showUndoTasks}
											onChange={(e) => setShowUndoTasks(e.target.checked)}
										/>
									}
									label="Exibir tarefas desfeitas"
									labelPlacement="end"
								/>
							</FormGroup>
						)}
					</Box>
					<Timeline
						sx={{
							[`& .${timelineItemClasses.root}:before`]: {
								flex: 0,
								padding: 0,
							},
						}}
					>
						{formatedTasks.map((item, index) => {
							const isLastIndex = index === formatedTasks.length - 1;
							const isNotConcluded =
								item.concluded_at === null &&
								!isSuspendedOrCanceled
							item.advance_next_task === true
							const isUndo = item.type === "Desfeita";

							if ((status === "Suspenso" || status === "Cancelado") && item.concluded_at === null) {
								return
							}

							return (
								<TimelineItem
									key={item.id}
									sx={{
										paddingY: "10px",
									}}
								>
									<TimelineSeparator>
										<TimelineDot
											color="inherit"
											sx={{
												backgroundColor: isUndo
													? "#820909"
													: item.theme || "#f3f3f3",
											}}
										>
											{isUndo && (
												<UndoIcon
													sx={{
														color: "white",
													}}
												/>
											)}
											{isNotConcluded && !isUndo ? (
												<AccessAlarmIcon />
											) : (
												<>{!isUndo && <CheckIcon />}</>
											)}
										</TimelineDot>
										{!isLastIndex && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										{renderItemTimeline(item.type, item, isNotConcluded)}
										{item.concluded_at === null && !hasRestriction && (
											<Button variant="outlined" onClick={() => handleModalSearchUsersUpdate(item)} style={{ border: "none" }}>
												Atualizar Responsável
											</Button>
										)}

									</TimelineContent>
								</TimelineItem>
							);
						})}
					</Timeline>
				</Item>
			</Box>
			<ModalSearchUsers refetch={refetch} open={openModalUpdateUser} handleClose={() => setOpenModalUpdateUser(false)} task={task} />

		</>
	);
}
